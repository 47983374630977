<template>
    <div class="index">
        <div class="index-banner">
            <img src="../../assets/index-banner-pc.png" class="pc-banner" alt="">
            <img src="../../assets/index-logo.png" class="logo" alt="">
            <h1 class="title">{{ $t('index.title') }}</h1>
        </div>
        <van-notice-bar class="notice" left-icon="volume-o" color="#FF0000" background="#FDE6E6" :text="announcements?.title" @click="toAnnouncementDetail">
            <img src="../../assets/icon-more.png" class="icon-more" slot="right-icon" alt="">
        </van-notice-bar>
        <van-tabs class="main" animated title-active-color="#FC1157" @click="tabChange" v-model="tabIndex">
            <van-tab :title="$t(item.category_name)" v-for="item in category" :key="`category_${item.id}`">
                <div class="index-card">
                    <a :href="item.url" target="_blank" class="index-card__new" :class="index<3?'card-rank'+index:(tabIndex > 0?'card-rank-tabIndex':'')" v-for="(item, index) in list" :key="`article_${item.id}`">
                        <div class="flex-1 flex overflow-hidden align-items__center">
                            <div :class="'img-index img-index'+index " v-if="index<3">{{ '0'+(index+1) }}</div>
                            <span class="index-new__index" v-else>{{ index >= 9 ? index + 1 : `0${index + 1}` }}</span>
                            <img class="index-card__cover" :src="`${IMAGE_URL}${item.image}`" alt="" v-if="tabIndex > 0">
                            <div class="van-ellipsis fs-16">{{ item[`${lang}_title`] }}</div>
                        </div>
                        <div class="index-new__view">
                            <img class="icon-hot" src="../../assets/icon-hot.png" alt="">
                            {{ item.views || '--' }}{{$t('万')}}
                        </div>
                    </a>
                </div>
            </van-tab>

            <!--      <van-tab title="音乐榜">-->
            <!--        <div class="index-card">-->
            <!--          <div class="index-card__new" :class="{'mt-0': index === 0}" v-for="(item, index) in 10" :key="index">-->
            <!--            <div class="flex-1 flex overflow-hidden align-items__center">-->
            <!--              <span class="index-new__index" :class="`new-${index}`">{{ index >= 9 ? index + 1 : `0${index + 1}` }}</span>-->
            <!--              <img class="index-card__cover" src="https://tikto-charity.com/uploads/20231113/0730e2dfe3e78ff1f0385703645b006e.jpg" alt="">-->
            <!--              <div class="van-ellipsis fs-16">这是一段最多显示一行的文字，多余的内容会被省略</div>-->
            <!--            </div>-->
            <!--            <div class="index-new__view">1919.0万</div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--      </van-tab>-->
        </van-tabs>
        <Tabbar :key="lang">
            <van-notice-bar slot="right" class="notice pc-notice" left-icon="volume-o" color="#FF0000" background="#FDE6E6" :text="announcements?.title" @click="toAnnouncementDetail">
                <img src="../../assets/icon-more.png" class="icon-more" slot="right-icon" alt="">
            </van-notice-bar>
        </Tabbar>
    </div>
</template>

<script>
import Tabbar from "@/components/Tabbar/Tabbar.vue";
import { mapActions, mapState } from "vuex";
import { getCategory, getArticleByCategoryId, getAnnouncements } from '@/api';
import { IMAGE_URL } from '@/config';

export default {
    data() {
        return {
            category: [],
            tabIndex: 0,
            list: [],
            announcements: null,
            IMAGE_URL,
            show: true,
            password: "",
        }
    },
    computed: {
        ...mapState(['lang'])
    },
    components: {
        Tabbar,
    },
    methods: {
        ...mapActions(['fetchUser']),
        toAnnouncementDetail() {
            this.$router.push({ path: '/announcement_detail', query: { id: this.announcements.id } })
        },
        _getCategory() {
            getCategory().then(res => {
                this.category = res.data;
                this._getArticleByCategoryId()
            })
        },
        _getArticleByCategoryId() {
            getArticleByCategoryId({ id: this.category[this.tabIndex].id }).then(res => {
                this.list = res.data
            })
        },
        tabChange() {
            this._getArticleByCategoryId()
        },
        _getAnnouncements() {
            getAnnouncements().then(res => {
                this.announcements = res.data
            })
        },
    },
    created() {
        this.fetchUser();
        this._getCategory();
        this._getAnnouncements();
    }
}
</script>

<style lang="less" scoped>
.index-card__cover {
    width: 38px;
    height: 38px;
    object-fit: cover;
    margin-right: 13px;
    border-radius: 4px;
    flex-shrink: 0;
}
.index-new__index {
    display: block;
    margin-right: 13px;
    font-weight: 500;
    color: #fc1157;
}
.index-new__view {
    margin-left: 26px;
    display: flex;
    align-items: center;
    .icon-hot {
        width: 14px;
        height: 14px;
    }
}
.index-card__new {
    margin: 0 12px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #303133;
    line-height: 40px;
    border-bottom: 1px solid #f0f0f0;
    .img-index {
        width: 18px;
        height: 24.69px;
        margin: 0 4px;
        padding-bottom: 4px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        color: #ffffff;
    }
    .img-index0 {
        background: url(../../assets/img-index0.png) left top no-repeat;
        background-size: cover;
    }
    .img-index1 {
        background: url(../../assets/img-index1.png) left top no-repeat;
        background-size: cover;
    }
    .img-index2 {
        background: url(../../assets/img-index2.png) left top no-repeat;
        background-size: cover;
    }
}
.card-rank-tabIndex {
    line-height: 62px;
}
.card-rank0 {
    margin: 6px 0;
    height: 54px;
    padding: 0 8px;
    border-radius: 10px;
    background: #fef8f3;
}
.card-rank1 {
    margin: 6px 0;
    height: 54px;
    padding: 0 8px;
    border-radius: 10px;
    background: #f6f8fc;
}
.card-rank2 {
    margin: 6px 0;
    height: 54px;
    padding: 0 8px;
    border-radius: 10px;
    background: #fcf8f7;
}
.index-card {
    padding: 0 16px;
    // background: #fff;
    border-radius: 6px;
}
.index-banner {
    width: 100%;
    height: 213px;
    background: url("../../assets/index-top-bg.png") left -44px no-repeat;
    background-size: cover;
    overflow: hidden;
    .logo {
        display: block;
        width: 122px;
        margin: 12px auto 15px auto;
    }
    .title {
        font-size: 18px;
        color: #000000;
        text-align: center;
    }
}
.notice {
    margin-top: -80px;
    height: 26px;
    .icon-more {
        width: 10px;
        height: 10px;
        margin: 0 6px;
    }
}
.main {
    width: 100%;
    border-radius: 10px 10px 0 0;
    background: url(../../assets/login_bg.png) left top no-repeat #fff;
    background-size: 100%;
    margin-top: 6px;
    overflow: hidden;
}
.pc-banner {
    display: none;
}
@media only screen and (min-width: 750px) {
    .index-banner {
        width: 100%;
        height: initial;
        background: none;
        overflow: hidden;
        .logo {
            position: absolute;
            left: 22.7%;
            top: 9.43vw;
            display: block;
            width: 14.8vw;
            margin: 0;
        }
        .title {
            position: absolute;
            left: 14.84%;
            top: 16.4vw;
            font-weight: 600;
            font-size: 40px;
            color: #000000;
            width: 32%;
            text-align: center;
        }
        .pc-banner {
            display: block;
            width: 100%;
            position: relative;
        }
    }
    .notice {
        display: none;
    }
    .notice.pc-notice {
        display: flex;
        margin-top: 0;
        width: 100%;
        height: 37px;
        background: #fde6e6;
        border-radius: 18px;
        padding: 0 20px;
        .icon-more {
            width: 16px;
            height: 16px;
            margin: 0 15px;
        }
        /deep/ .van-notice-bar__left-icon {
            min-width: 20px;
            font-size: 20px;
        }
        /deep/ .van-notice-bar__wrap {
            font-weight: 500;
            font-size: 16px;
        }
    }
    /deep/ .van-tabs--line .van-tabs__wrap {
        height: 60px;
        .van-tab {
            font-size: 16px;
        }
        .van-tabs__line {
            height: 4px;
        }
    }
    .card-rank-tabIndex {
        line-height: 78px;
    }
    .card-rank0 {
        margin: 24px 0;
        height: 78px;
        padding: 0 78px;
        border-radius: 10px;
        background: #fef8f3;
    }
    .card-rank1 {
        margin: 24px 0;
        height: 78px;
        padding: 0 78px;
        border-radius: 10px;
        background: #f6f8fc;
    }
    .card-rank2 {
        margin: 24px 0;
        height: 78px;
        padding: 0 78px;
        border-radius: 10px;
        background: #fcf8f7;
    }
    .index-card__cover {
        width: 38px;
        height: 38px;
        object-fit: cover;
        margin-right: 13px;
        border-radius: 4px;
        flex-shrink: 0;
    }
    .index-new__index {
        display: block;
        width: 28px;
        margin: 0 6px;
        font-size: 16px;
        flex-shrink: 0;
        text-align: center;
    }
    .index-new__view {
        margin-left: 26px;
        font-size: 16px;
        .icon-hot {
            width: 18px;
            height: 18px;
        }
    }
    .index-card__new {
        margin: 24px 0;
        padding: 0 30px;
        line-height: 78px;
        .van-ellipsis {
            font-size: 18px;
        }
        .img-index {
            width: 28px;
            height: 38px;
            margin: 0 6px;
            padding-bottom: 0px;
            font-size: 16px;
        }
    }
}
</style>
